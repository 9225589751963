import React, { Component } from 'react';
import { connect } from 'react-redux';
import localStorage from 'local-storage';
import { remove } from 'lodash-es';
import ReactSVG from 'react-svg';
import { Trans } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import { refreshPageHeader, checkIfLoggedIn, openUserMessage, openUserMessageCenter, showAlert } from '../../store/actions';
import './WelcomePage.css';
import { actionTypes, eventActions } from '../../store/actions/actionTypes';
import LoadingDots from '../../Components/UI/LoadingDots';
import { userTypes } from '../../services/enums';

class WelcomePage extends Component {
  constructor (props) {
    super(props);
    this.props.refreshPageHeader('login');
    this.props.disableTopNavPlx(false);
  }

  componentDidMount () {
    // this.checkIfLoggedIn();
  }

  async checkIfLoggedIn () {
    const data = await this.props.checkIfLoggedIn();
    const params = window.location.search;
    if (!data || !data.user) {
      this.props.history.push('/intro');
    } else {
      if (data.user && data.user.public_id) hotjar.identify(data.user.public_id, { userProperty: data.user.userType || 'user' });
      if (data && data.unReviewedDoc && data.unReviewedDoc.public_id) {
        this.props.history.push(`/fillPaperwork/document/${data.unReviewedDoc.public_id}`);
      } else if (data && data.unReviewedSurvey && data.unReviewedSurvey._id) {
        this.props.history.push(`/fillPaperwork/survey/${data.unReviewedSurvey._id}`);
      } else {
        console.log('data.messages', data.messages)
        this.timeout = setTimeout(() => {
          const session = localStorage.get('activeSession') || false;
          if (session) {
            this.unfinishedSessionAlert()
            return;
          }
          const dontShowAgain = localStorage.get(`intro-dontShowAgain`) || false;
          if (!dontShowAgain) this.openIntroductionModal(this.props.user)
          else if (data.messages && data.messages.length > 0) {
            const localUpdate = localStorage.get(`aspire-updates`) || '';
            const massages = remove(data.messages, (msg) => ((msg && !msg.aspireUpdate) || (msg && msg.aspireUpdate && (localUpdate !== msg.data.msgId))));
            if (massages && massages.length > 0) {
              this.props.saveMessages(massages);
              this.props.openUserMessageCenter();
            }
          }
          clearTimeout(this.timeout)
        }, 1500)
        // this.props.history.push(`/feed/${data.user.public_id}`);
        this.props.history.push(`/feed/${data.user.public_id}${params && params.length > 1 ? `${params}` : ''}`);
      }
    }
  }

  creatingContent() {
    const svgIcon =  'https://aspire123.s3.eu-central-1.amazonaws.com/onBoarding/user_workout.svg';
    const text = 'unfinishedSession_sub';
    return (
      <span>
        <div className="width100 marginBottom5px">
          <ReactSVG src={svgIcon} className="svg210px150px" loading={() => (<LoadingDots active />)} />
        </div>
        <span className="font16 text-wordBreak">
          <Trans i18nKey={text} />
        </span>
      </span>
    );
  }

  unfinishedSessionAlert () {
    const session = localStorage.get('activeSession');
    const title = <Trans i18nKey="notice" />;
    const content = this.creatingContent()
    const data = {
      isOpen: true,
      title,
      content,
      className: 'ModalPage',
      id: eventActions.ALERT_MODAL,
      disableCurtain: true,
      closeAlertBtn: {
        text: <Trans i18nKey="cancel" />,
        onClick: () => {
          localStorage.remove('activeSession');
        }
      },
      buttonRight: {
        className: 'flex primaryBtnBg',
        text: <Trans i18nKey="confirm" />,
        onClick: () => {
          this.props.hideAlert();
          this.props.history.push(`/startSession/start/${session.programId}`);
        }
      }
    };
    this.props.showAlert(data);
  }

  openIntroductionModal (user = {}) {
    const data = {
      isOpen: true,
      isModal: true,
      id: eventActions.WELCOME_USER_MODAL,
      className: 'PageModal CongratsModal IntroductionModal',
      user,
      dataKey: 'introduction',
      buttonRight: {
        text: <i className="la la-arrow-left iconFontSize30 goBackIconColor iconShadow" />,
        onClick: () => {
          this.props.hideAlert();
        }
      }
    };
    this.props.showAlert(data);
  }

  render () {
    return (
      <div className="LoaderPage flex-100 layout-column layout-align-center-center fullSizeWindow ">
        <div className="flex-initial layout-column text-center layout-align-center-center fadeIn-anime">
          <img src="https://aspire123.s3.eu-central-1.amazonaws.com/logo/aspireLogo_180x200" className="aspireIcon" />
          <h1 className="text-uppercase fontWeight600 aspireName">Aspire</h1>
          <h4 className="text-uppercase fontWeight600 aspireName">the App is now closed, thank you everyone</h4>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userR.userInfo,
  authenticated: state.userR.authenticated
});

const mapDispatchToProps = dispatch => ({
  showAlert: data => dispatch(showAlert(data)),
  hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
  saveMessages: (messages) => dispatch({ type: actionTypes.SAVE_ASPIRE_MASSAGES, payload: messages}),
  checkIfLoggedIn: () => dispatch(checkIfLoggedIn()),
  refreshPageHeader: page => dispatch(refreshPageHeader(page)),
  openUserMessage: data => dispatch(openUserMessage(data)),
  openUserMessageCenter: data => dispatch(openUserMessageCenter(data)),
  disableTopNavPlx: isDisable => dispatch({ type: actionTypes.DISABLE_TOP_NAV_PLX, payload: isDisable })
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
